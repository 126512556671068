import { useState, useEffect, useMemo } from 'react';
import { Col, Form, Row, Input, Button, Label, Spinner } from 'reactstrap';
import OptionsForm from '../../layouts/forms/OptionsForm';
import Loader from '../../layouts/loader/Loader';
import ModalClickable from '../../layouts/modals/ModalClickable';
import intercambioApiService from '../../services/http/intercambioApiService';
import otrosApiService from '../../services/http/otrosApiService';
import Table from '../ui/Table';

const INITIAL_OPTIONS = { centre: [], info: [], curs: [], grau: [] };

const FormularioIntercambio = () => {
  const [isDownloaded, setIsDownloaded] = useState(false);

  // Opciones
  const [options, setOptions] = useState(INITIAL_OPTIONS);

  // Datos del formulario.
  const [headquarter, setHeadquarter] = useState(null);
  const [courseYear, setCourseYear] = useState(null);
  const [type, setType] = useState(null);
  const [course, setCourse] = useState(null);

  // Datos de los alumnos.
  const [students, setStudents] = useState([]);
  const [studentsSelected, setStudentsSelected] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  // Filtro de documento de identidad.
  const [search, setSearch] = useState('');

  // Loaders.
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [isLoadingAlumnos, setIsLoadingAlumnos] = useState(false);

  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoadingOptions(true);

      try {
        const optionsResp = await otrosApiService.cargarOpcionesSII();

        setOptions((prev) => ({ ...prev, ...optionsResp }));
        setHeadquarter(optionsResp.centre[0].codigo);
        setCourseYear(optionsResp.curs[0].codigo);
        setCourse(optionsResp.grau[0].codigo);
        setType(optionsResp.info[0].codigo);
      } catch (error) {
        setOptions(INITIAL_OPTIONS);
        setHeadquarter(null);
        setCourseYear(null);
        setCourse(null);
        setType(null);
      } finally {
        setIsLoadingOptions(false);
      }
    };

    fetchOptions();
  }, []);

  const handleSelection = (ccodcli) => {
    setStudentsSelected((prev) => {
      const newStudentsSelected = prev.includes(ccodcli)
        ? prev.filter((id) => id !== ccodcli)
        : [...prev, ccodcli];

      return newStudentsSelected;
    });
  };

  // Alumnos con la acción de seleccionar.
  const studentsWithAction = useMemo(
    () =>
      students.map((student) => ({
        ...student,
        actions: (
          <Input
            type="checkbox"
            id={student.ccodcli}
            name="option"
            checked={studentsSelected.includes(student.ccodcli)}
            onChange={() => handleSelection(student.ccodcli)}
          />
        ),
      })),
    [students, studentsSelected],
  );

  const handleSearchStudents = async (e) => {
    setIsLoadingAlumnos(true);
    e.preventDefault();

    try {
      const dataAlumnosActas = await intercambioApiService.buscarAlumnosServeiIntercanvi({
        headquarter,
        ano: courseYear,
        grado: course,
      });

      setStudents(dataAlumnosActas);
    } catch (error) {
      setStudents([]);
    } finally {
      setStudentsSelected([]);
      setAllChecked(false);
      setSearch('');
      setIsLoadingAlumnos(false);
    }
  };

  const handleCheckAll = () => {
    if (!allChecked) {
      const newStudentsSelected = students.map((a) => a.ccodcli);
      setStudentsSelected(newStudentsSelected);
    } else {
      setStudentsSelected([]);
    }

    setAllChecked((prev) => !prev);
  };

  const sendData = async () => {
    setIsDownloaded(true);

    try {
      await intercambioApiService.generarXML({
        headquarter,
        evaluaciones: type,
        ano: courseYear,
        grado: course,
        ccodclis: studentsSelected,
      });
    } catch (error) {
      // No hacer nada específico aquí.
    }
  };

  return (
    <>
      {isDownloaded ? (
        <ModalClickable
          color="primary"
          header="Petición recibida correctamente. El archivo se enviará a su correo electrónico una vez esté generado"
        />
      ) : (
        ''
      )}

      <Form>
        {!isLoadingOptions ? (
          <>
            <Row className="mb-3">
              <Col sm>
                <Label for="centros">Centro</Label>
                <Input
                  type="select"
                  name="centros"
                  id="centros"
                  onChange={({ target: { value } }) => setHeadquarter(value)}
                >
                  <OptionsForm datos={options.centre} />
                </Input>
              </Col>
              <Col sm className="mt-2 mt-md-0">
                <Label for="tipo">Tipo</Label>
                <Input
                  type="select"
                  name="tipo"
                  id="tipo"
                  onChange={({ target: { value } }) => setType(value)}
                >
                  <OptionsForm datos={options.info} />
                </Input>
              </Col>
            </Row>
            <Row className="mt-0 mt-md-2">
              <Col sm className="mt-2 mt-md-0">
                <Label for="curso">Curso</Label>
                <Input
                  type="select"
                  name="curso"
                  id="curso"
                  onChange={({ target: { value } }) => setCourseYear(value)}
                >
                  <OptionsForm datos={options.curs} />
                </Input>
              </Col>
              <Col sm className="mt-2 mt-md-0">
                <Label for="grado">Grado</Label>
                <Input
                  type="select"
                  name="grado"
                  id="grado"
                  onChange={({ target: { value } }) => setCourse(value)}
                >
                  <OptionsForm datos={options.grau} />
                </Input>
              </Col>
            </Row>
            <Row className="mt-0 mt-md-2">
              <Col sm className="mt-6 mt-md-0 text-start">
                <Button className="mt-3 w-auto" onClick={handleSearchStudents}>
                  {isLoadingAlumnos ? (
                    <Spinner color="primary" style={{ height: '15px', width: '15px' }} />
                  ) : (
                    <span>Filtrar</span>
                  )}
                </Button>
              </Col>
              <Col sm className="mt-6 mt-md-0 text-center">
                <Button
                  className={`mt-3 w-auto ${!students.length ? 'disabled' : ''}`.trim()}
                  onClick={handleCheckAll}
                >
                  {allChecked ? 'Desmarcar todos' : 'Marcar todos'}
                </Button>
              </Col>
              <Col sm className="mt-3 mt-md-0 text-end">
                <Button
                  className={`mt-3 w-auto ${
                    !students.length || !studentsSelected.length ? 'disabled' : ''
                  }`.trim()}
                  onClick={() => sendData()}
                >
                  Generar XML
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <Loader />
        )}
      </Form>
      {students.length > 0 && (
        <>
          <Row className="mt-4">
            <Col sm className="mt-2 mt-md-0" md={6}>
              <Label for="search">Buscar</Label>
              <Input
                name="search"
                id="search"
                value={search}
                onChange={({ target: { value } }) => setSearch(value)}
              />
            </Col>
          </Row>
          <Table
            className="mt-2"
            columns={[
              { Header: 'Seleccionar', accessor: 'actions', sortable: false },
              {
                Header: 'ID',
                accessor: 'ccodcli',
              },
              {
                Header: 'Nombre',
                accessor: 'sNombre',
              },
              {
                Header: 'Apellidos',
                accessor: 'sApellidos',
              },
              {
                Header: 'Documento',
                accessor: 'CDNICIF',
              },
            ]}
            data={studentsWithAction.filter((student) => {
              const fields = ['ccodcli', 'sNombre', 'sApellidos', 'CDNICIF'];
              return fields.some((field) =>
                student[field]?.toString().toLowerCase().includes(search.toLowerCase()),
              );
            })}
          />
        </>
      )}
    </>
  );
};

export default FormularioIntercambio;
