import { ModalBody, ModalFooter, Modal, Button } from 'reactstrap';
import PropTypes from 'prop-types';

const ModalHeaderBody = ({ open = false, setOpen = () => {}, title, size = 'md', children }) => {
  return (
    <Modal size={size} isOpen={open} centered>
      <ModalBody>
        <h3 className="text-center mt-3">{title}</h3>
        {children}
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setOpen(false)}>Cerrar</Button>
      </ModalFooter>
    </Modal>
  );
};

ModalHeaderBody.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.any,
  title: PropTypes.any,
  size: PropTypes.string,
  children: PropTypes.any,
};

export default ModalHeaderBody;
