import ReactTable from 'react-table-v6';
import PropTypes from 'prop-types';

const translations = {
  previousText: '<',
  nextText: '>',
  loadingText: 'Cargando..',
  ofText: 'de',
  rowsText: 'líneas',
  noDataText: 'Datos no encontrados',
  pageText: 'Página',
};

const Table = ({
  columns = [],
  data = [],
  defaultPageSize = 10,
  showPaginationBottom = true,
  className = '',
  showPageSizeOptions = false,
}) => {
  return (
    <ReactTable
      {...translations}
      columns={columns}
      data={data}
      defaultPageSize={defaultPageSize}
      showPaginationBottom={showPaginationBottom}
      className={`-striped -highlight text-center ${className}`.trim()}
      showPageSizeOptions={showPageSizeOptions}
    />
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.arrayOf(PropTypes.any),
  defaultPageSize: PropTypes.number,
  showPaginationBottom: PropTypes.bool,
  className: PropTypes.string,
  showPageSizeOptions: PropTypes.bool,
};

export default Table;
