import HttpService from './httpService';

class IntercambioApiService extends HttpService {
  constructor(baseUrl, generalCatch) {
    super(baseUrl, generalCatch);
  }

  async generarXML({ headquarter, evaluaciones, ano, grado, ccodclis }) {
    return this.post(
      'generarXML',
      { ccodclis },
      { params: { headquarter, evaluaciones, ano, grado } },
    );
  }

  async buscarAlumnosServeiIntercanvi({ headquarter, ano, grado }) {
    return this.get('buscarAlumnosServeiIntercanvi', {
      params: { headquarter, ano, grado },
    });
  }
}

const intercambioApiService = new IntercambioApiService(process.env.REACT_APP_NODE_API);
export default intercambioApiService;
