import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

const MyEditor = ({
  initialValue = '',
  initHeight = 500,
  content,
  setContent,
  handleBlurBody,
  blurBody,
  disabled,
}) => {
  const handleEditorChange = (text) => {
    setContent(text); // Actualiza el contenido en el estado
  };

  return (
    <>
      {content !== undefined ? (
        <div className={blurBody && content.length === 0 ? 'border border-danger error' : ''}>
          <Editor
            initialValue={initialValue}
            apiKey="faecrmatsv4p2c7kteiytqqvq3cct7qqsdfidv9gi4v5l22j"
            init={{
              branding: false,
              height: initHeight,
              menubar: true,
              plugins: [
                'table advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
                'link',
              ],
              toolbar:
                'undo redo | styleselect | bold italic | alignleft aligncenter alignright | code',
              language: 'es', // Configura el idioma a español
            }}
            onEditorChange={handleEditorChange}
            onBlur={handleBlurBody}
            disabled={disabled}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

MyEditor.propTypes = {
  initialValue: PropTypes.string,
  initHeight: PropTypes.number,
  content: PropTypes.any,
  setContent: PropTypes.any,
  blurBody: PropTypes.any,
  handleBlurBody: PropTypes.any,
  disabled: PropTypes.bool,
};

export default MyEditor;
